
import { Component, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import { IpdcProductTheme } from '@govflanders/mbp-admin-panel-shared';
import RoleGuard from '@/utility/auth/RoleGuard.vue';
import { mixins } from 'vue-class-component';
import draggable from 'vuedraggable';

@Component({
  components: {
    PageHeader,
    PageHeaderNavigationAction,
    Anchor,
    PageHeaderAction,
    draggable,
  },
})
export default class MbpThemeSearch extends mixins(RoleGuard) {
  @Getter('ipdcProductThemeSearch/isFailed')
  isFailed!: boolean;
  @Getter('ipdcProductThemeSearch/isLoading')
  isLoading!: boolean;
  @Getter('ipdcProductThemeSearch/isSuccess')
  isSuccess!: boolean;
  @Getter('ipdcProductThemeSearch/results')
  results: IpdcProductTheme[];

  sortedResults: IpdcProductTheme[] = [];
  updateIsLoading = false;

  get isOrderChanged() {
    return (
      this.results?.map(theme => theme.code)?.toString() !==
      this.sortedResults?.map(theme => theme.code)?.toString()
    );
  }

  async mounted() {
    if (!this.results?.length) {
      await this.search();
    }
  }

  @Watch('results', { immediate: true })
  private onResultsChange() {
    this.sortedResults = this.results?.map(x => ({ ...x }));
  }

  public async search() {
    await this.$store.dispatch('ipdcProductThemeSearch/execute');
  }

  public async submit() {
    this.updateIsLoading = true;
    const changedThemes = this.sortedResults.filter(sortedTheme => {
      const originalOrder = this.results.find(theme => theme.code === sortedTheme.code).order;
      return originalOrder !== sortedTheme.order;
    });
    await this.$store
      .dispatch('ipdcProductTheme/bulkUpdate', changedThemes)
      .then(() => {
        this.$toast.success(this.$t('ipdc.mbp-theme.sort.success'));
        this.search();
      })
      .catch(() => this.$toast.error(this.$t('ipdc.mbp-theme.sort.error')))
      .finally(() => (this.updateIsLoading = false));
  }

  public revert() {
    this.sortedResults = this.results.map(x => ({ ...x }));
  }

  public moveItem(indexToMove, newIndex) {
    if (newIndex > this.sortedResults.length - 1 || newIndex < 0) {
      return;
    }
    const itemToMove = this.sortedResults[indexToMove];

    this.sortedResults.splice(indexToMove, 1);
    this.sortedResults.splice(newIndex, 0, itemToMove);
    this.onOrderChange({ newIndex });
  }

  public onOrderChange({ newIndex }) {
    const movedItem = this.sortedResults[newIndex];

    const orderOfPrevious = this.sortedResults[newIndex - 1]?.order || 0;
    let orderOfNext = this.sortedResults[newIndex + 1]?.order || orderOfPrevious + 1000;

    if (orderOfNext - orderOfPrevious <= 1) {
      orderOfNext = this.updateOrderOfNextTheme(newIndex + 1);
    }

    const calculatedOrder = Math.round((orderOfPrevious + orderOfNext) / 2);
    movedItem.order = calculatedOrder;
  }

  private updateOrderOfNextTheme(index) {
    const itemToShift = this.sortedResults[index];
    const orderOfItemToShift = itemToShift.order;
    let orderOfNext = this.sortedResults[index + 1]?.order || orderOfItemToShift + 1000;
    if (orderOfNext - orderOfItemToShift <= 1) {
      orderOfNext = this.updateOrderOfNextTheme(index + 1);
    }

    itemToShift.order = Math.round((orderOfItemToShift + orderOfNext) / 2);
    return itemToShift.order;
  }
}
